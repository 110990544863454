import React from "react";

const CarScroll = (props) => {
  return (
    <div className="flex flex-col bg-white m-auto p-auto ">
      <h1 className="flex py-5 lg:px-20 md:px-10 px-5 lg:mx-40 md:mx-20 mx-5 font-bold text-orange-500 text-4xl ">
        TOP BRANDS
      </h1>
      <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
        <div className="flex flex-nowrap lg:ml-40 md:ml-20 ml-10 ">
          <div className="inline-block px-3 ">
            <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out flex justify-start items-center">
              <img
                src="https://www.carlogos.org/logo/Mercedes-Benz-logo-2008-1920x1080.png"
                alt=""
              />
            </div>
          </div>
          <div className="inline-block px-3">
            <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out flex justify-center items-center">
              <img
                src="https://www.carlogos.org/logo/Volkswagen-logo-2015-1920x1080.png"
                alt=""
              />
            </div>
          </div>
          <div className="inline-block px-3">
            <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out flex justify-center items-center">
              <img
                src="https://www.carlogos.org/logo/Volkswagen-logo-2015-1920x1080.png"
                alt=""
              />
            </div>
          </div>
          <div className="inline-block px-3">
            <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out flex justify-center items-center">
              <img
                src="https://www.carlogos.org/logo/Volkswagen-logo-2015-1920x1080.png"
                alt=""
              />
            </div>
          </div>
          <div className="inline-block px-3">
            <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out flex justify-center items-center">
              <img
                src="https://www.carlogos.org/logo/Volkswagen-logo-2015-1920x1080.png"
                alt=""
              />
            </div>
          </div>
          <div className="inline-block px-3">
            <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out flex justify-center items-center">
              <img
                src="https://www.carlogos.org/logo/Volkswagen-logo-2015-1920x1080.png"
                alt=""
              />
            </div>
          </div>
          <div className="inline-block px-3">
            <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out flex justify-center items-center">
              <img
                src="https://www.carlogos.org/logo/Volkswagen-logo-2015-1920x1080.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarScroll;
